.cookies-policy {
  width: 100%;
  max-height: 97vh;
  padding: 20px 0;
  background-color: #fff;
  border-top: 1px solid #006655;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  &__title {
    margin-bottom: 20px;
  }
  &__description {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    a {
      white-space: nowrap;
      @media (max-width: 768px) {
        white-space: normal;
      }
    }
  }
  &__button {
    min-width: auto !important;
    white-space: nowrap;
    margin-left: 20px !important;
    @media (max-width: 768px) {
      margin: 20px 0 0 !important;
    }
  }
}