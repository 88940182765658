.custom-switch {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 3px;
  border-radius: 100px;
  position: relative;
  &__button {
    padding: 7px 16px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.45);
  }
  &__cover {
    padding: 7px 16px;
    border-radius: 47px;
    background-color: #fff;
    font-size: 14px;
    line-height: 17px;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 2;
    cursor: default;
    transition: all .2s linear;
    &._active {
      left: calc(100% - 3px);
      transform: translateX(-100%);
    }
  }
}