.global-search {
  padding: 52px 0 60px;
  @media (max-width: 1085px) {
    padding: 24px 0 32px;
  }
  &__controls {
    text-align: center;
    margin-bottom: 24px;
  }
  &__wrap {
    width: 100%;
    max-width: 765px;
    margin: 0 auto;
    position: relative;
  }
  &__search {
    &-input {
      width: 100%;
      padding: 10px 40px 10px 10px;
      border: 1px solid #B3B3B3;
    }
  }
  &__btn-wrap {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
  }
}