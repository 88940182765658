.content {
  width: 100%;
  max-width: 1166px;
  padding: 0 20px;
  margin: 0 auto;
  @media (max-width: 1085px) {
    padding: 0 12px;
  }
}

.layout {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  &__content {
    flex: 1 1 auto;
  }
  &__wrap {
    flex: 1 1 auto;
    display: flex;
    & > .sidebar {
      margin-right: 50px;
    }
    &-left {
      flex: 1 1 auto;
      overflow: hidden;
      & > .global-search {
        padding: 32px 0 24px;
        .global-search__wrap {
          max-width: 100%;
        }
      }
    }
  }
}