.user-login {
  .MuiButtonBase-root {
    padding: 0 !important;
  }
  .MuiAvatar-root {
    width: 36px;
    height: 36px;
  }
}

#user-info {
  .MuiList-root {
    padding: 0;
    max-width: 206px;
  }
  .MuiMenuItem-root {
    padding: 12px;
    &:hover {
      .MuiTypography-root, .MuiIcon-root {
        color: #006655;
      }
    }
  }
  .MuiIcon-root {
    transition: color .2s linear;
  }
  .MuiListItemIcon-root {
    min-width: 32px;
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
  .MuiTypography-root {
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    color: #606060;
    transition: color .2s linear;
  }
  .user-info__menu {
    &-head {
      background-color: #006655;
      color: #fff;
      padding: 12px;
      font-size: 14px;
      p:last-child {
        margin-bottom: 0;
      }
    }
    &-link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
  }
}