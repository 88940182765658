.menu-sidebar {
  width: 300px;
  min-width: 300px;
  padding: 37px 25px 50px 0;
  @media (max-width: 1085px) {
    padding: 0 12px 12px;
  }
  &__logo {
    display: block;
    width: 230px;
    text-align: center;
    text-decoration: none !important;
    margin: 0 auto 32px;
    p {
      font-size: 27px;
      color: #000;
      text-align: left;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 16px;
  }
  &__list {
    list-style: none;
  }
  &__item {
    padding: 12px 0;
    border-bottom: 1px solid #B3B3B3;
    &:first-child {
      border-top: 1px solid #B3B3B3;
    }
  }
}

.menu-link {
  color: #333;
  font-size: 16px;
  line-height: 20px;
  &:hover {
    color: #006655;
    text-decoration: none;
  }
  &.active {
    color: #006655;
  }
  &__list {
    list-style: none;
    padding-left: 8px;
  }
  &__item {
    padding-left: 16px;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #606060;
      position: absolute;
      top: 11px;
      left: 3px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .menu-link {
      color: #606060;
      font-size: 14px;
      line-height: 18px;
      &:hover, &.active {
        color: #006655;
      }
    }
  }
  &__accordion {
    box-shadow: none !important;
    &-top {
      padding: 0 !important;
      min-height: auto !important;
      .MuiAccordionSummary-content {
        margin: 0 !important;
      }
    }
    &-body {
      padding: 8px 0 0 !important;
    }
  }
}